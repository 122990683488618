<template>
  <footer class="d-flex">
    <div class="vege">
      <img src="../assets/VEGETARIAN_FOOD.webp" alt="Image Végétarian Food">
      <p class="vege_txt">Tous nos burgers, hot-dogs, bagels, tapas, burritos et fajitas
        sont disponibles en option Végétarien.
        Remplacement de la viande par une galette végétarienne.</p>
    </div>
    <div class="logo">
      <img src="../assets/LOGO_DETOURE.webp" alt="Logo Bus-Stop-Diner">
    </div>
    <div class="info">
      <div class="maps">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1847.4636393224082!2d1.4862296692651151!3d49.09218634842691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6cbdfe49c1a6b%3A0x57cabc1e1db6033d!2sBus%20Stop%20Diner!5e0!3m2!1sfr!2sfr!4v1600340094658!5m2!1sfr!2sfr"
            width="600" height="450" frameborder="0" style="border:0;" allowfullscreen=""
            aria-hidden="false"
            tabindex="0"></iframe>
      </div>
      <div class="carte">
        <a :href="cartes[0].source_url">
          <img src="../assets/CARTE.webp" alt="Télécharger la carte">
        </a>
      </div>
    </div>
    <div class="desert">
      <img src="../assets/IMAGE_BSD_DESERT.webp" alt="Image Bus-Stop-Diner">
    </div>
    <div class="vente">
      <div class="emporter_logo">
        <img src="../assets/shopping-bag.webp" alt="Logo à emporter">
      </div>
      <p class="vente_txt">VENTE A EMPORTER & LIVRAISON</p>
      <div class="livraison_logo">
        <img src="../assets/UBER-EATS.webp" alt="Logo Uber Eats">
      </div>
    </div>
    <div class="legal">
      <router-link class="legal_txt" to="/mention-legale">Mentions Légales</router-link>
    </div>
  </footer>
</template>

<script>

export default {
  name: "Footer",
  data() {
    return {
      cartes: []
    }
  },
  mounted() {
    this.$http.get('wp/v2/media?categories=74').then(response => {
      for (let carte in response.data) {
        this.cartes.push(response.data[carte]);
      }
    }, error => {
      alert(error)
    });
  }
}
</script>

<style scoped>
footer {
  background-image: url("../assets/brick-wall-185086.webp");
  background-size: 25%;
  color: black;
  text-align: left;
  font-family: 'PT Sans Narrow', sans-serif;
  flex-wrap: wrap;
  margin-bottom: 0;
}

a {
  font-weight: 100;
  color: white;
}

p {
  margin: 0;
}

.vege {
  width: 100%;
  margin-bottom: 10px;
  background-color: rgba(3, 110, 23, 0.7);
}

.vege img {
  width: 95%;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.vege_txt {
  margin: -60px 20% 10px;
  color: white;
}

.logo {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.logo img {
  width: 50%;
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.maps {
  width: 80%;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 10%;
  margin-right: 10%;
}

iframe {
  width: 100%;
}

.carte {
  width: 80%;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 10%;
  margin-right: 10%;
}

.carte img {
  width: 100%;
}

.desert {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 10%;
  margin-right: 10%;
}

.desert img {
  width: 100%;
}

.vente {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #feead4;
  text-align: center;
  height: 100%;
  line-height: 10vh;
  font-weight: bold;
  padding: 1% 5%;
}

.vente_txt {
  width: 80%;
  text-align: center;
  font-size: 12px;
}

.legal {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #feead4;
  text-align: center;
  line-height: 5vh;
  font-weight: bold;
  padding: 1% 5%;
}

.legal_txt {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: black;
}

.emporter_logo {
  width: 10%;
  margin-right: 10px;
}

.emporter_logo img {
  width: 100%;
}

.livraison_logo {
  width: 10%;
  margin-left: 10px;
}

.livraison_logo img {
  width: 100%;
}

@media (min-width: 576px) {
  .vege_txt {
    margin: -100px 20% 10px;
  }

  .logo {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .logo img {
    width: 40%;
  }

  .desert {
    margin-left: 40px;
    margin-right: 40px;
  }

  .vente_logo img {
    width: 15%;
  }
}

@media (min-width: 768px) {
  .vege_txt {
    margin: -130px 20% 10px;
  }

  .logo {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .logo img {
    width: 30%;
  }

  .desert {
    margin-left: 40px;
    margin-right: 40px;
  }

  .vente_txt {
    font-size: 28px;
  }

  .legal_txt {
    font-size: 16px;
  }

  .vente_logo img {
    width: 10%;
  }
}

@media (min-width: 992px) {
  footer {
    background-image: url("../assets/brick-wall-185086.webp");
    background-size: 25%;
    /*background-color: #feead4;*/
    margin-left: 19%;
    margin-right: 19%;
  }

  .vege_txt {
    margin: -105px 20% 10px;
    font-size: 1.25rem;
  }

  .info {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .maps {
    width: 50%;
    height: 21vh;
    text-align: center;
    margin-bottom: 20px;
    margin-left: 5%;
    margin-right: 5%;
  }

  iframe {
    width: 100%;
    height: 100%;

  }

  .carte {
    width: 50%;
    text-align: center;
    margin-bottom: 20px;
    margin-left: 5%;
    margin-right: 5%;
  }

  .carte img {
    width: 100%;
  }

  .desert {
    margin-left: 5%;
    margin-right: 5%;
  }

  .vente_txt {
    width: 86%;
  }

  .emporter_logo {
    width: 7%;
    margin-right: 20px;
  }

  .livraison_logo {
    width: 7%;
    margin-left: 20px;
  }
}

@media (min-width: 1200px) {
  .vege_txt {
    margin: -125px 20% 10px;
  }

  .maps {
    height: 25vh;
  }
}

@media (min-width: 1600px) {
  .vege_txt {
    margin: -165px 20% 40px;
  }

  .maps {
    height: 36vh;
  }

  .vente_txt {
    width: 90%;
  }

  .emporter_logo {
    width: 5%;
    margin-right: 20px;
  }

  .livraison_logo {
    width: 5%;
    margin-left: 20px;
  }
}

</style>
