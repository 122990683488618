<template>
    <div class="Client-post d-flex flex-column">
        <div class="d-flex flex-column justify-content-center loader visibility-hidden" v-if="loader">
            <div class="logo_loader">
                <img src="../assets/LOGO_DETOURE.webp" alt="Logo Bus-Stop-Diner">
            </div>
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="actu">
            <h2>Les Actualités</h2>
        </div>
        <carousel :per-page="1" :mouse-drag="false" :speed="3000" :autoplayTimeout="8000"
                  :direction="rigth" :autoplay=true :loop=true>
            <slide v-for="slider in sliders" :key="slider.id" class="d-flex flex-row">
                <div class="articles-content d-flex flex-column">
                    <div class="articles-img">
                        <img :src="slider.better_featured_image.source_url" :alt="slider.better_featured_image.alt_text">
                    </div>
<!--                    <h2 class="article_txt" v-html="slider.title.rendered"></h2>-->
                </div>
            </slide>
        </carousel>
        <div class="succes">
            <h2>Nos Meilleurs Succès</h2>
        </div>
        <div v-for="popular in populars" :key="popular.id" class="d-flex flex-column success_product">
            <div class="media-content d-flex flex-row">
                <div class="popular_img">
                    <router-link class="is-tab nav-item job_title " :to="'/la-carte/' + popular.id">
                        <img :src="popular.images[0].src" :alt="popular.images[0].alt">
                    </router-link>
                </div>
                <div class="d-flex flex-column">
                    <h3 class="popular_title">{{ popular.name }}</h3>
                    <p class="popular_desc" v-html="popular.description"></p>
<!--                    <p class="popular_price" v-html="popular.price_html"></p>-->
                </div>
            </div>
        </div>
        <div class="succes">
            <h2>Les Collectors</h2>
        </div>
        <div v-for="collector in collectors" :key="collector.id" class="d-flex flex-column success_product">
            <div class="media-content d-flex flex-row">
                <div class="popular_img">
                    <router-link class="is-tab nav-item job_title " :to="'/la-carte/' + collector.id">
                        <img :src="collector.images[0].src" :alt="collector.images[0].alt">
                    </router-link>
                </div>
                <div class="d-flex flex-column">
                    <h3 class="popular_title">{{ collector.name }}</h3>
                    <p class="popular_desc" v-html="collector.description"></p>
                    <p class="popular_price" v-html="collector.price_html"></p>
                </div>
            </div>
        </div>
        <div class="carte">
            <router-link class="carte_button is-tab nav-item" to="/la-carte">La Carte Complete</router-link>
        </div>
    </div>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';

export default {
    name: "Main",
    components: {
        Carousel,
        Slide,
    },
    data() {
        return {
            populars: [],
            collectors: [],
            sliders: [],
            loader: true,
        }
    },
    methods: {
        loaderToggle() {
            setTimeout(() => {
                this.loader = false
            }, 1000)
        }
    },
    mounted() {
        this.$woocommerce.get("products?category=71&order=asc&orderby=title")
            .then(response => {
                for (let popular in response.data) {
                    this.populars.push(response.data[popular]);
                }
            })
            .catch((error) => {
                console.log(error.response.data);
            });
        this.$woocommerce.get("products?category=72&order=asc&orderby=title")
            .then(response => {
                for (let collector in response.data) {
                    this.collectors.push(response.data[collector]);
                }
            })
            .catch((error) => {
                console.log(error.response.data);
            });
        this.$http.get('wp/v2/posts?categories=70&per_page=100').then(response => {
            for (let slider in response.data) {
                this.sliders.push(response.data[slider]);
            }
        }, error => {
            alert(error)
        });
        this.loaderToggle()
    }
}
</script>

<style scoped>

.loader {
    position: fixed;
    z-index: 1000000;
    background-color: #fbe9d5;
    width: 100%;
    height: 150%;
    margin-top: -60vh;
    overflow: hidden !important;
}

.logo_loader {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

.logo_loader img {
    margin-top: 30px;
    width: 70%;
}

.spinner-border {
    margin-left: 46%;
    margin-right: 46%;
    margin-top: 70px;
}

.actu {
    font-family: 'Pacifico', cursive;
    margin-top: 45px;
    text-align: left;
    margin-left: 20px;
    font-weight: bold;
}

p {
    font-family: 'PT Sans Narrow', sans-serif;
    font-weight: 700;
}

a {
    color: black;
}

.articles-img {
    width: 100%;
    height: auto;
}

.articles-img img {
    width: 100%;
}

.succes {
    font-family: 'Pacifico', cursive;
    text-align: center;
    margin-left: 20px;
    font-weight: bold;
}

.success_product {
    margin: 10px 20px;
}

.media-content {
    width: 100%;
}

.popular_img {
    max-width: 40%;
    margin-right: 15px;
}

.popular_img img {
    width: 100%;
    background-color: black;
}

.popular_title {
    /*font-family: 'PT Sans Narrow', sans-serif;*/
    font-family: 'Pacifico', cursive;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
}

.popular_desc {
    font-family: 'PT Sans Narrow', sans-serif;
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    margin-bottom: 0;
}

.popular_price {
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 12px;
    text-align: left;
}

.carte {
    margin: 20px;
}

.carte_button {
    background: linear-gradient(to top left, #ffa199, white);
    border: 1px solid black;
    border-radius: 25px;
    padding: 3px 10px;
}

@media (min-width: 576px) {

    .logo_loader {
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
    }

    .spinner-border {
        margin-left: 47%;
        margin-right: 47%;
        margin-top: 70px;
    }

    .popular_img {
        max-width: 40%;
    }

    .success_product {
        margin: 20px 30px;
    }
}

@media (min-width: 768px) {

    .spinner-border {
        margin-left: 48%;
        margin-right: 48%;
        margin-top: 70px;
    }

    .popular_img {
        width: 100%;
    }

    .popular_title {
        font-size: 18px;
    }

    .popular_desc {
        font-size: 16px;
    }

    .popular_price {
        font-size: 16px;
    }
}

@media (min-width: 992px) {

    .loader {
        width: 103%;
        margin-left: -19vw;
    }

    .Client-post {
        background-image: url("../assets/brick-wall-185086.webp");
        background-size: 25%;
        /*background-color: #feead4;*/
        margin-left: 19%;
        margin-right: 19%;
        padding-top: 2%;
    }

    .popular_title {
        font-size: 20px;
    }

    .popular_desc {
        font-size: 18px;
    }

    .popular_price {
        font-size: 18px;
    }
}

@media (min-width: 1200px) {

    .loader {
        position: fixed;
        z-index: 1000000;
        background-color: #fbe9d5;
        width: 100%;
        height: 150%;
        margin-top: -550px;
        overflow: hidden !important;
    }

    .logo_loader {
        width: 50%;
        margin-left: 25%;
        margin-right: 25%;
    }

    .logo_loader img {
        margin-top: 30px;
        width: 70%;
    }

    .spinner-border {
        margin-left: 49%;
        margin-top: 70px;
    }

    .Client-post {
        padding-top: 4%;
    }

    .popular_title {
        font-size: 20px;
    }

    .popular_price {
        font-size: 18px;
    }
}

@media (min-width: 1600px) {

    .loader {
        width: 103%;
        margin-left: -19vw;
    }

    .popular_title {
        font-size: 20px;
    }

    .popular_price {
        font-size: 18px;
    }
}
</style>
