<template>
    <div class="Client-post">
        <div class="back">
            <router-link class="is-tab nav-item job_title back_button" to="/la-carte"><span>Retour</span>
            </router-link>
        </div>
        <div class="article_single">
            <div class="d-flex justify-content-center loader visibility-hidden" v-if="loader">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="media-content d-flex">
                <h3 class="article_title">{{ article.name }}</h3>
                <div class="popular_img">
                    <img :src="article.images[0].src" alt="">
                </div>
            </div>
            <ProductDescription :article="article" :atribute="atribute" :varients="varients">
            </ProductDescription>
        </div>
    </div>
</template>

<script>
import ProductDescription from "./ProductDescription";

export default {
    name: "SingleProduct",
    components: {
        ProductDescription,
    },
    data() {
        return {
            article: {},
            image: '',
            atribute: [],
            varients: []
        }
    },
    mounted() {
        this.$woocommerce.get("products/" + this.$route.params.id)
            .then(response => {
                this.article = response.data
                this.image = response.data.images[0].src
                this.atribute = response.data.attributes
                let material = this.atribute[0].options
                material.forEach(metaux => {
                    this.materialOptions.push(metaux);
                })
                response.data.meta_data[0].value = 0
                let test = response.data.meta_data[0].value
                test += 1
                test -= 1
                response.data.meta_data[0].value = test
                this.relatedid = response.data.related_ids
                this.relatedid.forEach(id => {
                    this.$woocommerce.get("products/" + id)
                        .then(response => {
                            this.relateds = [...this.relateds, response.data];
                        })
                        .catch((error) => {
                            console.log(error.response.data);
                        });
                })
            }, error => {
                alert(error)
            });
        this.$woocommerce.get("products/" + this.$route.params.id + "/variations?order=asc&orderby=id&per_page=20")
            .then(response => {
                for (let varient in response.data) {
                    this.varients.push(response.data[varient]);
                }
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    },
}
</script>

<style scoped>

.Client-post {
    padding-top: 10%;
}

.back {
    position: absolute;
    float: left;
    margin-left: 20px;
    margin-top: 10px;
}

.back_button {
    background: url("../../assets/left-arrow.webp") no-repeat left top;
    background-size: 20%;
    padding-left: 20px;
    color: #000000;
}

.loader {
    margin-top: 150px;
    margin-bottom: 150px;
}

.media-content {
    flex-direction: column;
}

.article_single {
    margin-top: 40px;
}

.popular_img {
    max-width: 40%;
    margin-left: 30%;
    margin-right: 30%;
}

.popular_img img {
    width: 100%;
    background-color: black;
}

@media (min-width: 992px) {
    .Client-post {
        background-image: url("../../assets/brick-wall-185086.webp");
        background-size: 25%;
        margin-left: 19%;
        margin-right: 19%;
        padding-top: 7%;
    }
}

@media (min-width: 1600px) {
    .Client-post {
        padding-top: 4%;
    }
}

</style>
