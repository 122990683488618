<template>
    <div id="app" :class="{change_app: scrollPosition > 75}">
        <Header></Header>
        <router-view/>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
    name: 'App',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            scrollPosition: null
        }
    },
    methods: {
        updateScroll() {
            this.scrollPosition = window.scrollY
        }
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },
}
</script>

<style>

.change_app {
    padding-top: 36% !important;
    transition-duration: 1s;
    transition-timing-function: linear;
    transition-property: padding-top;
}

#app {
    font-family: 'PT Sans Narrow', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    padding-top: 47%;
    width: 100%;
    background-image: url("assets/brick-wall-185086.webp");
    background-size: 25%;
}

.vue-simple-drawer {
    backdrop-filter: blur(10px);
    background: #fbe9d5 !important;
    width: 50%;
    height: 100%;
}

.VueCarousel-dot-container {
    margin: 0 0 20px !important;
}

.vue-simple-drawer .close-btn .leftright, .vue-simple-drawer .close-btn .rightleft {
    background-color: #000000 !important;

}

.VueCarousel-pagination[data-v-438fd353] {
    margin-top: -60px;
    z-index: 1;
}

.VueCarousel-dot {
    background-color: #b7b7b7 !important;
}

.VueCarousel-dot--active {
    background-color: #ffffff !important;
}

.wp-block-image {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    min-width: fit-content;
}

.wp-block-image img {
    width: 100%;
    height: 100%;
}

.wp-block-gallery {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

.wp-block-gallery img {
    width: 100%;
    height: 100%;
}

.commandes {
    display: flex;
    flex-direction: row;
}

.commandes > .wp-block-group__inner-container {
    width: 30%;
    margin-left: 20%;
    display: flex;
    flex-wrap: nowrap;
}

.reseaux_sociaux {
    flex-wrap: nowrap;
}

.reseaux_sociaux > .wp-block-group__inner-container {
    width: 30%;
    margin-left: -10%;
    display: flex;
    flex-wrap: nowrap;
}

.reseaux_sociaux_2 {
    flex-wrap: nowrap;
}

.reseaux_sociaux_2 > .wp-block-group__inner-container {
    width: 30%;
    margin-left: 5%;
    display: flex;
    flex-wrap: nowrap;
}

@media (min-width: 576px) {

    .change_app {
        padding-top: 23% !important;
    }

    #app {
        padding-top: 45%;
    }

    .commandes > .wp-block-group__inner-container {
        width: 20%;
        margin-left: 30%;
        display: flex;
        flex-wrap: nowrap;
    }

    .reseaux_sociaux > .wp-block-group__inner-container {
        width: 20%;
        margin-left: 10%;
        display: flex;
        flex-wrap: nowrap;
    }

    .reseaux_sociaux_2 > .wp-block-group__inner-container {
        width: 20%;
        margin-left: 20%;
        display: flex;
        flex-wrap: nowrap;
    }

}

@media (min-width: 768px) {

    .change_app {
        padding-top: 19% !important;
    }

    #app {
        padding-top: 29%;
    }

}

@media (min-width: 860px) {

    .commandes > .wp-block-group__inner-container {
        width: 20%;
        margin-left: 32%;
        display: flex;
        flex-wrap: nowrap;
    }

    .reseaux_sociaux > .wp-block-group__inner-container {
        width: 20%;
        margin-left: 12%;
        display: flex;
        flex-wrap: nowrap;
    }

    .reseaux_sociaux_2 > .wp-block-group__inner-container {
        width: 20%;
        margin-left: 22%;
        display: flex;
        flex-wrap: nowrap;
    }
}

@media (min-width: 992px) {

    .change_app {
        padding-top: 12% !important;
    }

    #app {
        padding-top: 21%;
        background-image: url("assets/BACKGROUND.webp");
        background-size: contain;
    }

    .vue-simple-drawer {
        width: 30%;
    }

    .commandes > .wp-block-group__inner-container {
        width: 20%;
        margin-left: 30%;
        display: flex;
        flex-wrap: nowrap;
    }

    .reseaux_sociaux > .wp-block-group__inner-container {
        width: 20%;
        margin-left: 10%;
        display: flex;
        flex-wrap: nowrap;
    }

    .reseaux_sociaux_2 > .wp-block-group__inner-container {
        width: 20%;
        margin-left: 20%;
        display: flex;
        flex-wrap: nowrap;
    }

}

@media (min-width: 1200px) {

    .change_app {
        padding-top: 8% !important;
    }

    #app {
        padding-top: 16%;
    }

    .vue-simple-drawer {
        width: 20%;
    }
}

@media (min-width: 1600px) {

    .change_app {
        padding-top: 4% !important;
    }

    #app {
        padding-top: 11%;
    }

    .vue-simple-drawer {
        width: 15%;
    }

    .commandes > .wp-block-group__inner-container {
        width: 20%;
        margin-left: 34%;
        display: flex;
        flex-wrap: nowrap;
    }

    .reseaux_sociaux > .wp-block-group__inner-container {
        width: 20%;
        margin-left: 18%;
        display: flex;
        flex-wrap: nowrap;
    }

    .reseaux_sociaux_2 > .wp-block-group__inner-container {
        width: 20%;
        margin-left: 26%;
        display: flex;
        flex-wrap: nowrap;
    }

}
</style>
