import Vue from 'vue';
import VueRouter from 'vue-router';
import App from "../App";
import Main from "../components/Main";
import Boutique from "../components/Boutique/Boutique";
import SingleProduct from "../components/Boutique/SingleProduct";
import Contact from "../components/Contact";
import Galerie from "@/components/Galerie";
import Presentation from "@/components/Presentation";
import LegalNotice from "@/components/Legal/LegalNotice.vue";

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '*',
            component: App
        },
        {
            path: '/',
            component: Main
        },
        {
            path: '/la-carte',
            component: Boutique
        },
        {
            path: '/la-carte/:id',
            component: SingleProduct
        },
        {
            path: '/presentation',
            component: Presentation
        },
        {
            path: '/galerie',
            component: Galerie
        },
        {
            path: '/contact',
            component: Contact
        },
        {
            path: '/mention-legale',
            component: LegalNotice
        }
    ],
    scrollBehavior() {
        return {x: 0, y: 0}
    }
});

