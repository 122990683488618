<template>
    <div class="sidebar">
        <div v-for="categorie in categories" :key="categorie.id" class="categorie">
            <div class="media-content">
                <a class="categorie_title" @click="$emit('get-id', categorie.id)">{{ categorie.name }}</a>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "Sidebar",
    data() {
        return {
            categories: [],
        }
    },
    mounted() {
        this.$woocommerce.get("products/categories?parent=0")
            .then(response => {
                for (let categorie in response.data) {
                    this.categories.push(response.data[categorie]);
                }
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    }
}
</script>

<style scoped>

.sidebar {
    display: flex;
    flex-wrap: wrap;
}

.categorie {
    margin-bottom: 5px;
    margin-left: 20px;
}

.categorie_title a {
    padding-left: 20px;
    font-family: 'Pacifico', cursive;
    font-weight: 400;
    color: #000000;

}

a:hover {
    color: #b7b7b7 !important;
}

</style>
