<template>
  <div class="Client-post">
    <div class="d-flex flex-column justify-content-center loader visibility-hidden" v-if="loader">
      <div class="logo_loader">
        <img src="../../assets/LOGO_DETOURE.webp" alt="Logo Bus-Stop-Diner">
      </div>
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="actu">
      <h2>Mentions Légales</h2>
    </div>
    <div class="presentation d-flex">
      <div v-for="page in pages" :key="page.id" class="d-flex flex-row presentation_content">
        <div class="media-content d-flex flex-column">
          <h3>{{ page.title.rendered }}</h3>
          <p v-html="page.content.rendered"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Mention Legale",
  data() {
    return {
      pages: [],
      loader: true,
    }
  },
  methods: {
    loaderToggle() {
      setTimeout(() => {
        this.loader = false
      }, 1000)
    }
  },
  mounted() {
    this.$http.get('wp/v2/posts?categories=84').then(response => {
      for (let page in response.data) {
        this.pages.push(response.data[page]);
      }
    }, error => {
      alert(error)
    });
    this.loaderToggle()
  }
}
</script>

<style scoped>

.loader {
  position: fixed;
  z-index: 1000000;
  background-color: #fbe9d5;
  width: 100%;
  height: 150%;
  margin-top: -60vh;
  overflow: hidden !important;
}

.logo_loader {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.logo_loader img {
  margin-top: 30px;
  width: 70%;
}

.spinner-border {
  margin-left: 46%;
  margin-right: 46%;
  margin-top: 70px;
}

.actu {
  font-family: 'Pacifico', cursive;
  margin-top: 45px;
  text-align: left;
  margin-left: 20px;
  font-weight: bold;
}

.presentation {
  justify-content: left;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.presentation_content {
  width: 100%;
}

.media-content {
  width: 100%;
  text-align: left;
}

.wp-block-image img {
  width: 10%;
}

@media (min-width: 576px) {
  .logo_loader {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media (min-width: 768px) {

  .spinner-border {
    margin-left: 48%;
    margin-right: 48%;
    margin-top: 70px;
  }
}

@media (min-width: 992px) {

  .loader {
    width: 103%;
    margin-left: -19vw;
  }

  .Client-post {
    background-image: url("../../assets/brick-wall-185086.webp");
    background-size: 25%;
    margin-left: 19%;
    margin-right: 19%;
    padding-top: 2%;
  }

}

@media (min-width: 1200px) {
  .loader {
    position: fixed;
    z-index: 1000000;
    background-color: #fbe9d5;
    width: 100%;
    height: 150%;
    margin-top: -550px;
    overflow: hidden !important;
  }

  .logo_loader {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }

  .logo_loader img {
    margin-top: 30px;
    width: 70%;
  }

  .spinner-border {
    margin-left: 49%;
    margin-top: 70px;
  }

  .Client-post {
    padding-top: 4%;
  }

}

@media (min-width: 1600px) {

  .loader {
    width: 103%;
    margin-left: -19vw;
  }
}

</style>
