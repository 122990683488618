import Vue from "vue"
import axios from "axios"

const instance = axios.create({
    baseURL: 'https://api.bus-diner.nathan-rousseau.fr'
})

Vue.prototype.$axios = instance

const WooCommerceRestApi = require("@woocommerce/woocommerce-rest-api").default;


const WooCommerce = new WooCommerceRestApi({
    url: 'https://api.bus-diner.nathan-rousseau.fr', // Your store URL
    consumerKey: 'ck_dbfc48118e1cda16b55203f2e13ea75e02c554cd', // Your consumer key
    consumerSecret: 'cs_0db2ce15838531b3889eab035a30b8ea9174eeef', // Your consumer secret
    version: 'wc/v3', // WooCommerce WP REST API version
    queryStringAuth: true,
});

Vue.prototype.$woocommerce = WooCommerce

export {
    instance,
    WooCommerce
}