<template>
    <div class="info_article d-flex flex-column">
        <div class="article_header d-flex flex-column">
            <div class="article_composition d-flex flex-row">
            </div>
        </div>
        <p class="popular_desc" v-html="article.description"></p>
<!--        <p>Options :</p>-->
<!--        <div class="d-flex flex-column">-->
<!--            <ul v-for="varient in varients" :key="varient.id" class="d-flex flex-column success_product">-->
<!--                <li class="media-content d-flex flex-column">-->
<!--                    <div v-for="attributes in varient.attributes" :key="attributes.id">-->
<!--                        <p class="popular_options" v-html="attributes.option"></p>-->
<!--                    </div>-->
<!--                    <p class="popular_title">Prix : {{ varient.price }} €</p>-->
<!--                </li>-->
<!--            </ul>-->
<!--        </div>-->
<!--        <h4>Prix : </h4>-->
<!--        <p class="popular_price" v-html="article.price_html"></p>-->
    </div>
</template>

<script>

export default {
    name: "ProductDescription",
    props: ['article', 'atribute', 'varients'],
}
</script>

<style scoped>
.info_article {
    margin: 50px 10%;
    width: 80%;
    text-align: left;
}

ul {
    background: url("../../assets/rec.webp") no-repeat left top;
    background-size: 4%;
    padding-left: 25px;
    color: #000000;
    margin-left: 10px;
}

.popular_options {
    margin-bottom: 0;
}

@media (min-width: 576px) {
    ul {
        background-size: 3%;
        padding-left: 25px;
        margin-left: 10px;
    }
}

@media (min-width: 768px) {
    .info_article {
        margin: 50px 10%;
    }

    ul {
        background-size: 2%;
        padding-left: 25px;
        margin-left: 10px;
    }
}

@media (min-width: 992px) {
    .info_article {
        margin: 50px 10% 0px 10%;
        padding-bottom: 50px;
    }
}

@media (min-width: 1600px) {
    ul {
        background-size: 1%;
        padding-left: 25px;
        margin-left: 10px;
    }
}
</style>