<template>
    <div>
        <nav class="header" :class="{change_header: scrollPosition > 75}">
            <div class="menu-button" @click="toggle" :class="{change_menu_button: scrollPosition > 75}">
                <img src="../assets/menu.webp" alt="icone menu">
            </div>
            <Drawer @close="toggle" align="left" :closeable="true">
                <div v-if="open">
                    <div class="menu">
                        <div class="menu-column">
                            <router-link class="is-tab nav-item" to="/">Accueil</router-link>
                            <hr>
                            <router-link class="is-tab nav-item" to="/la-carte">La Carte</router-link>
                            <hr>
                            <router-link class="is-tab nav-item" to="/contact">Contact</router-link>
                            <hr>
                            <router-link class="is-tab nav-item" to="/galerie">Photos</router-link>
                            <hr>
                            <router-link class="is-tab nav-item" to="/presentation">Présentation</router-link>
                            <hr>
                        </div>
                    </div>
                </div>
            </Drawer>
            <div class="etoiles"></div>
            <div class="d-flex flex-row top_header" :class="{change_header: scrollPosition > 75}">
                <div class="menu_desktop" :class="{change_menu_desktop: scrollPosition > 75}">
                    <router-link class="is-tab nav-item" to="/presentation">Présentation</router-link>
                    <router-link class="is-tab nav-item" to="/la-carte">La Carte</router-link>
                </div>
                <div class="div_logo">
                    <div class="logo">
                        <router-link to="/" class="logo_a" :class="{change_logo_triangle: scrollPosition > 75}">
                            <img src="../assets/TRIANGLE.webp" id="vwp-logo"
                                 alt="Logo Bus-Stop-Diner"/>
                        </router-link>
                    </div>
                    <div class="logo_mini">
                        <router-link to="/" class="logo_mini_a" :class="{change_logo: scrollPosition > 75}">
                            <img src="../assets/LOGO_DETOURE.webp" id="vwp-logo-mini"
                                 alt="Logo Bus-Stop-Diner"/>
                        </router-link>
                    </div>
                </div>
                <div class="menu_desktop" :class="{change_menu_desktop: scrollPosition > 75}">
                    <router-link class="is-tab nav-item" to="/contact">Contact</router-link>
                    <router-link class="is-tab nav-item" to="/galerie">Photos</router-link>
                </div>
            </div>
            <div class="exterieur d-flex flex-row" :class="{change_exterieur: scrollPosition > 75}">
                <div class="ext_left">
                    <img src="../assets/IMG_3600.webp" alt="Deventure du restaurant">
                </div>
                <div class="ext_right">
                    <img src="../assets/IMG_3605.webp" alt="Terrasse du restaurant">
                </div>
            </div>
            <div class="etoiles_bottom" :class="{change_etoiles: scrollPosition > 75}"></div>
        </nav>
    </div>
</template>

<script>
import Drawer from "vue-simple-drawer"

export default {
    name: 'Header',
    data() {
        return {
            open: false,
            search: '',
            scrollPosition: null
        }
    },
    components: {
        Drawer
    },
    props: {
        msg: String
    },
    methods: {
        toggle() {
            this.open = !this.open
        },
        updateScroll() {
            this.scrollPosition = window.scrollY
        }
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },

    watch: {
        $route() {
            this.open = false
        },
    }
}
</script>

<style scoped>

.header {
    font-family: 'PT Sans Narrow', sans-serif;
    font-weight: bold;
    font-size: 1.25rem;
    /*background-color: #fbe9d5;*/
    position: fixed;
    top: 0;
    /*height: 13vh;*/
    z-index: 100;
    width: 100%;
    transition-duration: 0.5s;
    transition-timing-function: linear;
}

/*change header when scroll*/

.change_header {
    transition: height 0.5s linear;
    height: 50px !important;
}

.change_menu_button {
    top: 15px !important;
}

.change_menu_desktop {
    line-height: 85px !important;
}

.change_logo_triangle {
    opacity: 0;
    /*transition: opacity 1s linear;*/
    transition-duration: 1s;
    transition-timing-function: linear;
    transition-property: opacity;
}

.change_logo {
    opacity: 1 !important;
    /*transition: opacity 1s linear;*/
    transition-duration: 1s;
    transition-timing-function: linear;
    transition-property: opacity;
}

.change_exterieur {
    opacity: 0;
    transition: opacity 1s linear;
}

.change_etoiles {
    margin-top: 0 !important;
    transition: margin-top 0.5s linear;
}


/*///////*/

.top_header {
    height: 40px;
    background-color: #fbe9d5;
}

.div_logo {
    text-align: center;
    width: 100%;
    z-index: 100;
}

.logo {
    position: absolute;
    width: 95%;
    margin-left: 2.5%;
}

.logo_a {
    transition-duration: 1s;
    transition-timing-function: linear;
}

.logo_mini {
    position: absolute;
    width: 100%;
}

.logo_mini_a {
    transition-duration: 1s;
    transition-timing-function: linear;
    opacity: 0;
}

.menu_desktop {
    display: none;
}

.exterieur {
    width: 100%;
    position: absolute;
    top: 45px;
    transition-duration: 1s;
    transition-timing-function: linear;
}

.ext_left {
    width: 50%;
}

.ext_left img {
    width: 100%;
}

.ext_right {
    width: 50%;
}

.ext_right img {
    width: 100%;
}

a {
    color: #000000 !important;
    width: 35%;
}

a:hover {
    color: #b7b7b7 !important;
    text-decoration: none;
}

.menu-button {
    position: inherit;
    left: -40px;
    top: 10px;
    color: white;
    z-index: 1000;
}

.menu-button span {
    display: none;
}

.menu-button img {
    width: 20%;
}

.menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.menu-column {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-bottom: 30px;
    text-align: center;
}

.nav-item {
    padding-top: 3px;
    padding-bottom: 3px;
    width: 100%;
}

hr {
    width: 35px;
    border-bottom: 1px solid #000000;
    margin-top: 0;
}

#vwp-logo {
    width: 60%;
}

#vwp-logo-mini {
    width: 30%;
}

::-webkit-scrollbar {
    display: none;
}

h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

.etoiles {
    width: 100%;
    height: 7px;
    background-image: url("../assets/BANDEAU_ETOILE.webp");
    background-size: cover;
    background-repeat-y: no-repeat;
    background-position-y: center;
}

.etoiles_bottom {
    width: 100%;
    height: 7px;
    background-image: url("../assets/BANDEAU_ETOILE.webp");
    background-size: cover;
    background-repeat-y: no-repeat;
    background-position-y: center;
    margin-top: 36.7%;
}

@media (min-width: 400px) {
    #vwp-logo-mini {
        width: 23%;
    }
}

@media (min-width: 576px) {

    /*change header when scroll*/
    .change_menu_button {
        top: 15px !important;
    }

    /*///////*/
    .etoiles {
        height: 8px;
        background-size: 130%;
    }

    .etoiles_bottom {
        height: 8px;
        background-size: 130%;
        margin-top: 37%;
    }

    #vwp-logo {
        width: 53%;
    }

    #vwp-logo-mini {
        width: 19%;
    }

    .menu-button {
        top: 11px;
    }

}

@media (min-width: 768px) {

    /*change header when scroll*/
    .change_menu_button {
        top: 18px !important;
    }

    /*///////*/
    .etoiles {
        height: 9px;
        background-size: 110%;
    }

    .etoiles_bottom {
        height: 9px;
        background-size: 110%;
        margin-top: 196px;
    }

    #vwp-logo {
        width: 38%;
    }

    #vwp-logo-mini {
        width: 14%;
    }

    .ext_left {
        width: 50%;
        height: 200px;
        background-image: url("../assets/IMG_3600.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: center;
    }

    .ext_left img {
        display: none;
    }

    .ext_right {
        width: 50%;
        height: 200px;
        background-image: url("../assets/IMG_3605.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: center;
    }

    .ext_right img {
        display: none;
    }
}

@media (min-width: 870px) {
    #vwp-logo {
        width: 34%;
    }

    #vwp-logo-mini {
        width: 12.5%;
    }
}

@media (min-width: 992px) {

    .change_menu_desktop {
        line-height: 48px !important;
    }

    .logo {
        position: absolute;
        width: 100%;
        margin-left: 0;
    }

    .menu-button {
        display: none;
    }

    .menu_desktop {
        display: flex;
        flex-direction: row;
        z-index: 1000;
        justify-content: space-around;
        line-height: 31px;
        width: 100%;
    }

    #vwp-logo {
        width: 85%;
    }

    #vwp-logo-mini {
        width: 34%;
    }

    .logo {
        width: 33%;
    }

    .logo_mini {
        width: 33%;
    }

    .etoiles {
        height: 10px;
        background-size: 100%;
    }

    .etoiles_bottom {
        height: 10px;
        background-size: 100%;
        margin-top: 195px;
    }
}

@media (min-width: 1200px) {

    #vwp-logo {
        width: 71%;
    }

    #vwp-logo-mini {
        width: 28%;
    }
}

@media (min-width: 1600px) {

    #vwp-logo {
        width: 53%;
    }

    #vwp-logo-mini {
        width: 21%;
    }

    .logo {
        width: 33%;
    }

    .etoiles {
        height: 12px;
        background-size: 80%;
    }

    .etoiles_bottom {
        height: 12px;
        background-size: 80%;
        margin-top: 193px;
    }
}

@media (min-width: 1800px) {
    #vwp-logo-mini {
        width: 18%;
    }
}

</style>
