<template>
    <div class="Collection-post d-flex">
        <div v-for="media in medias" :key="media.id" class="d-flex flex-row popular_img">
            <div class="media-content d-flex flex-column">
                <img class="collection-img" :src="media.source_url" :alt="media.alt_text">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WpCollection",
    data() {
        return {
            medias: []
        }
    },
    mounted() {
        this.$http.get('wp/v2/media?categories=75&per_page=100').then(response => {
            for (let media in response.data) {
                this.medias.push(response.data[media]);
            }
        }, error => {
            alert(error)
        });
    }
}
</script>

<style scoped>
.Collection-post {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.popular_img {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

.popular_img img {
    width: 100%;
    background-color: black;
}

.media-content {
    margin-bottom: 15px;
    width: 80%;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
}

@media (min-width: 576px) {
    .media-content {
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) {

    .Collection-post {
        flex-direction: row;
    }

    .popular_img {
        width: 40%;
        margin-left: 5%;
        margin-right: 5%;
    }

}

</style>
