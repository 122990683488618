import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import VueResouce from 'vue-resource';
import Vue from 'vue'
import App from './App'
import router from './router'
import "./components/Connection/http"
import VueAnalytics from 'vue-analytics';


Vue.use(VueAnalytics, {
    id: 'UA-182104965-1',
    router
})

Vue.config.productionTip = false;


Vue.use(VueResouce);
Vue.http.options.root = 'https://api.bus-diner.nathan-rousseau.fr/wp-json';

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
